import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const DivPerson = ({ data }) => {
    return (
        <>
            <div className="div-person">
                <StaticImage
                    src="../images/person.png"
                    loading="eager"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt={data.globalJson.info.name}
                />
            </div>
            <div className="div-info">
                <div className="block margin-top-minus">
                    <p className="name">
                        {data.globalJson.info.name}
                    </p>
                    <p className="info">
                        {data.globalJson.info.department}
                    </p>
                </div>
            </div>
        </>
    )
}

export default DivPerson
