import * as React from "react"

const DivSocialMobile = ({ data }) => {
    return (
        <div className="div-social-mobile">
            {data.globalJson.social.map((social, l) => (
                <a href={social.link} target="_blank" key={"social" + l}>
                    <img src={social.img.childImageSharp.fluid.srcWebp}
                        alt={social.alt}
                    />
                </a>
            ))}
        </div>
    )
}

export default DivSocialMobile
