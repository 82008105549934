import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const DivClique = ({ data }) => {
    return (
        <div className="div-clique">
            <img src={data.globalJson.info.imgClick.childImageSharp.fluid.srcWebp}
                alt='click'
            />
        </div>
    )
}

export default DivClique
