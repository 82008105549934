import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const DivCliqueMobile = ({ data }) => {
    return (
        <div className="div-clique-mobile">
            <img src={data.globalJson.info.imgClick.childImageSharp.fluid.srcWebp}
                alt='click' 
            />
        </div>
    )
}

export default DivCliqueMobile
