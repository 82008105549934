import * as React from "react"

const DivSocial = ({ data }) => {
    return (
        <div className="div-social">
            {data.globalJson.social.map((social, l) => (
                <a href={social.link} target="_blank" key={"social" + l}>
                    <img src={social.img.childImageSharp.fluid.srcWebp}
                        alt={social.alt}
                    />
                </a>

            ))}

        </div>
    )
}

export default DivSocial
